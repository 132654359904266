.nav{
    padding: 1rem 2.5rem;
    position: fixed;
    z-index: 999;
    background-color: #fff;
}

.logo{
    font-weight: 600;
    letter-spacing: .2rem;
    animation: flip alternate .5s;
    cursor: pointer;
}

@keyframes flip {
    0%{
        transform: translate(-180px);
    }
}
 
.nav-list{
    animation: animate alternate .5s;
}

@keyframes animate{
    0%{
        transform: translate(350px);
    }
}

li.nav-list:hover{
    background-color: #ddd;
    padding: 1rem;
}

.nav-list .nav-content, .sub-nav-content{
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    margin: 15px -15px;
    box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.sub-nav-content{
    float: right;
    margin-left: 200px;
    margin-top: -40px;
}

.nav-list .nav-content li a{
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    transition: all ease-in-out .3s;
}

.nav-list .nav-content li a:hover{
    color: #990b0bce;
    margin-left: 6px;
}    

.nav-list:hover .nav-content, .sub-nav-list .sub-nav-content{
    display: block;
}    

.hamburger{
    background-color: transparent;
    width: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    display: none;
}

@media only screen and (max-width:760px) {
    .hamburger{
    display: block;
    }

.nav-brand > ul.navbar{
    display: none;        
    position: absolute;
    top: 70%;
    right: 0;
    flex-direction: column;
    width: 50%;
    background-color: #fff;
    height: 100vh;
    border-top: 1px solid #000;
}
    
.nav-brand.expanded ul.navbar{
    display: block;
}

.nav-brand ul.navbar li{
    margin: 0;
}

.nav-list .nav-content, .sub-nav-content{
    min-width: 200px;
    z-index: 1;
}

.sub-nav-content{
    float: right;
    margin-left: 150px;
    margin-top: -40px;
}

} 
