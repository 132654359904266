@media only screen and (max-width: 900px) {
    
    .flex-column li:hover{
        background-attachment: #fff ;
        margin-top: 1rem;
    }
    
    .flex-column{
        flex-direction: column;
    }

    .flex-column .robot-txt, .flex-column .robot-img{
        width: 100%;
    }

}

@media only screen and (max-width: 768px) {
    .txt h1{
        font-size: 35px;
    }

    .txt p{
        font-size: 22px;
    }
}

@media only screen and (max-width: 425px) {
    .txt h1{
        font-size: 25px;
    }

    .txt p{
        font-size: 18px;
    }
}
