.robot-txt{
    width: 40%;
}

.robot-txt img{
    width: 60%;
}

.robot-img{
    width: 60%;
}