.bg-color{
    min-height: 80vh;
    background-image: url(../../images/IMG_0832.jpg);
    background-size: cover;
    background-position: center;
}

.bg{
    min-height: 80vh;
    background-image: linear-gradient(120deg, rgba(0, 0, 0, 0.85) 72%, rgba(181, 0, 48, 0) 100%) ;
    opacity: .7;
}

.txt{
    color: #fff;
    margin: 0 auto;
    width: 85%;
    height: 80vh;
    display: grid;
    place-items: center;
    animation: moveup alternate 1s;
}

@keyframes moveup{
    0%{
        transform: translateY(360px);
    }
    100%{
        transform: translateX(0px);
    }
}

.txt h1{
    font-size: 3rem;
    line-height: 2.4;
}

.txt p{
    font-size: 1.5rem;
}