.email-input{
    padding: 1rem 2rem;
    width: 100%;
    border-radius: 2rem;
    outline: none;
    border: 1px solid #fff;
}

.myIcon i{
    transition: all ease-in-out .4s;
}

.myIcon i:hover{
    transform: scale(1.2, 1.2);
}