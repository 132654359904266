/* font-family: 'Bai Jamjuree', sans-serif; */

*{
    padding: 0;
    margin: 0;  
    box-sizing: border-box;
}

body{
    font-family: 'Bai Jamjuree', sans-serif; 
}

img{
    width: 100%;
}

a{
    text-decoration: none;
    color: black;
}

ul{
    text-transform: uppercase;
}

li{
    list-style: none;
}

.d-flex{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.justify-content-center{
    justify-content: center;
}

.justify-content-between{
    justify-content: space-between;
}

.justify-content-around{
    justify-content: space-around;
}

.justify-content-evently{
    justify-content: space-evenly;
}

.align-center{
    text-align: center;
    align-items: center;
}

.btn{
    padding: 1rem 1.6rem;
    border-radius: 2rem;
    outline: none;
    border: 1px solid #000;
    color: #fff;
}

.btn:nth-child(1){
    padding: .5rem 1rem !important;
}

.btn-primary{
    background-color: #000;
}

.btn-secondary{
    background-color: rgb(53, 48, 48);
}

.btn-danger{
    background-color: red;
    border: none;
}

.btn-transparent{
    background-color: #fff;
    color: rgb(107, 99, 99);
    font-weight: bold;
    border: none;
    box-shadow: 0 0 0 10px 0 rgb(0 0 0 /10%);
}

.p-1{
    padding: 1rem;
}

.p-2{
    padding: 2rem;
}

.p3{
    padding: 3rem;
}

.p4{
    padding: 4rem;
}

.p5{
    padding: 5rem;   
}

.p6{
   padding: 6rem 0; 
}

.mb-1{
   margin-bottom: 1rem;
}

.mb-2{
   margin-bottom: 2.5rem;   
}

.mt-1{
   margin-top: 1rem;
}

.mt-2{
    margin-top: 2rem;
 }

 .mt-3{
    margin-top: 3rem;
 }

 .mt-4{
    margin-top: 4rem;
 }

 .mr-1{
    margin-right: 1rem;
 }

 .mr-2{
    margin-right: 2rem;
 }

 .ml-1{
    margin-left: 1rem;
 }

 .ml-2{
    margin-left: 1rem;
 }

 .lh-2{
    line-height: 1.6;
    letter-spacing: .05rem;
 }

 .bg-black{
    background-color: #000;
    color: #fff;
    width: 100%;
 }

 .robotics{
    width: 80%;
    margin: 0 auto;
 }

 .fw{
    font-weight: 400;
 }

 .fs-30{
    font-size: 30px;
 }

 .w-60{
    width: 60%;
    margin: 3rem auto;
 }